import * as messages from "@/locales/translations.json";
import { createI18n } from "vue-i18n";
import type { Locale } from "vue-i18n";

// If js i18n environment is not configured, we default to english
if( !window.__i18n ) {
  window.__i18n = {
    locale: "en",
    available: ["en"],
    translations: { en: {} }
  }
};

// Avoid setting up translations that are not required
let filteredMessages = {};

window.__i18n.available.forEach((locale: Locale) => {
  filteredMessages[locale] = messages[locale]
});

// Vue.use(VueI18n)
const i18n = createI18n<false>({
  locale: window.__i18n ? window.__i18n.locale : "en",
  messages: filteredMessages,
  fallbackLocale: "en",
  datetimeFormats: {
    en: {
      short: {
        year: "numeric",
        month: "short",
        day: "numeric"
      },
      long: {
        year: "numeric",
        month: "short",
        day: "numeric",
        weekday: "short",
        hour: "numeric",
        minute: "numeric"
      }
    },
    da: {
      short: {
        year: "numeric",
        month: "short",
        day: "numeric"
      },
      long: {
        year: "numeric",
        month: "short",
        day: "numeric",
        weekday: "short",
        hour: "numeric",
        minute: "numeric"
      }
    }
  },
  warnHtmlInMessage: "off",
});

// Merge in custom js translations
window.__i18n.available.forEach((locale: Locale) => {
  if(window.__i18n.translations[locale] ){
    i18n.global.mergeLocaleMessage(locale, window.__i18n.translations[locale])
  }
});

export { i18n };

export default i18n;
